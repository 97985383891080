<template>
  <div class="Authority">
    <Header title="我的任务" index="首页" titleOne="我的任务" :beforeTitle="beforeTitle " />
    <div class="content">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane
          v-for="(item,index) in tabsList"
          :key="index"
          v-show="item.show"
          :label="item.label"
          :name="item.name"
        >
          <component :is="item.vue" ref="item" v-if="activeName == item.name" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Cwsh from '../../components/myTask/financialReview.vue'
import Lzfp from '../../components/myTask/theCirculation.vue'
import Kpsk from '../../components/myTask/openShoukuan.vue'
import Htsub from '../../components/myTask/contractSubmit.vue'
import Htview from '../../components/myTask/contractReview.vue'
import Add from '../../components/myTask/addTask.vue'
import Help from '../../components/myTask/projectHelp.vue'
import XmTask from '../../components/myTask/xmTask.vue'
export default {
  data () {
    return {
      tabsList: [],
      beforeTitle: '合同审核',
      activeName: 'first',
      privilige_list: [],
    };

  },
  activated () {
    this.activeName
  },
  components: {
    Cwsh,
    Lzfp,
    Kpsk,
    Htsub,
    Htview,
    Add,
    Help,
    XmTask,
  },
  // mission_audit:合同审核
  // mission_order:项目任务
  // mission_invoice:开票收款
  // mission_flow:流转分配
  // mission_finance:财务审核
  // mission_contract:合同提交
  // mission_assist:项目协助
  // mission_addition:附加任务
  created () {
    let arr = localStorage.getItem('privilige')
    this.privilige_list = JSON.parse(arr)
    if (this.privilige_list.mission_audit) {
      let obj = {
        label: '合同审核',
        name: 'first',
        show: 'privilige_list.mission_audit',
        vue: 'Htview',
      }
      this.tabsList.push(obj)
    }
    if (this.privilige_list.mission_flow) {
      let obj = {
        label: '流转分配',
        name: 'second',
        show: 'privilige_list.mission_flow',
        vue: 'Lzfp',
      }
      this.tabsList.push(obj)
    }
    if (this.privilige_list.mission_invoice) {
      let obj = {
        label: '开票收款',
        name: 'third',
        show: 'privilige_list.mission_invoice',
        vue: 'Kpsk',
      }
      this.tabsList.push(obj)
    }
    if (this.privilige_list.mission_finance) {
      let obj = {
        label: '财务审核',
        name: 'fourth',
        show: 'privilige_list.mission_finance',
        vue: 'Cwsh',
      }
      this.tabsList.push(obj)
    }
    if (this.privilige_list.mission_contract) {
      let obj = {
        label: '合同提交',
        name: 'fifve',
        show: 'privilige_list.mission_contract',
        vue: 'Htsub',
      }
      this.tabsList.push(obj)
    }
    if (this.privilige_list.mission_order) {
      let obj = {
        label: '项目任务',
        name: 'six',
        show: 'privilige_list.mission_order',
        vue: 'XmTask',
      }
      this.tabsList.push(obj)
    }
    if (this.privilige_list.mission_addition) {
      let obj = {
        label: '附加任务',
        name: 'seven',
        show: 'privilige_list.mission_addition',
        vue: 'Add',
      }
      this.tabsList.push(obj)
    }
    if (this.privilige_list.mission_assist) {
      let obj = {
        label: '项目协助',
        name: 'eight',
        show: 'privilige_list.mission_assist',
        vue: 'Help',
      }
      this.tabsList.push(obj)
    }
    if (this.tabsList) {
      console.log(this.tabsList[0].name)
      this.activeName = this.tabsList[0].name
    }
  },
  methods: {
    handleClick (tab, event) {
      console.log(tab.name, event);
      this.beforeTitle = tab.label
      this.$refs.item[0].getList()
      console.log(this.$refs.item, 888888888)
    },
  },
};
</script>

<style scoped="scoped">
</style>
