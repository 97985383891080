<template>
  <!-- 我的任务-合同提交-子组件 -->
  <div class="Authority">
    <div class="topUsers" style="margin-top:24px">
      <div>
        <el-select @change="classSelect" v-model="contractParams.value_class" placeholder="请选择签订部门">
          <el-option label="全部" value="0"></el-option>
          <el-option
            v-for="item in options_class"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-select @change="humanSelect" v-model="contractParams.value_human" placeholder="请选择签订人">
          <el-option label="全部" value="0"></el-option>
          <el-option
            v-for="item in options_human"
            :key="item.id"
            :label="item.true_name"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-input
          v-model="contractParams.keywords"
          placeholder="请输入企业名称关键字"
          @keyup.enter.native="handleSearch()"
        ></el-input>
        <el-button class="search" @click="handleSearch()">查询</el-button>
      </div>
      <el-button
        v-if="privilige_list.contract.includes('add')"
        class="add"
        @click="editContract('')"
      >新增</el-button>
    </div>
    <el-table
      v-loading="loading"
      element-loading-text="正在加载中···"
      :data="contract.data"
      style="width: 100%"
    >
      <el-table-column prop="company_name" label="企业名称" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{
          scope.row.company_name ? scope.row.company_name : "-"
          }}
        </template>
      </el-table-column>
      <el-table-column prop="area" label="所属区域" :show-overflow-tooltip="true">
        <template slot-scope="scope">{{ scope.row.area ? scope.row.area : "-" }}</template>
      </el-table-column>
      <el-table-column prop="contract_name" label="合同名称" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{
          scope.row.contract_name ? scope.row.contract_name : "-"
          }}
        </template>
      </el-table-column>
      <el-table-column prop="ht_num" label="合同编号" :show-overflow-tooltip="true">
        <template slot-scope="scope">{{ scope.row.ht_num ? scope.row.ht_num : "-" }}</template>
      </el-table-column>
      <el-table-column prop="type" label="合同类型" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span v-if="scope.row.type == 1">独立</span>
          <span v-if="scope.row.type == 2">非独立</span>
        </template>
      </el-table-column>
      <el-table-column prop="project_num" label="项目数量" :show-overflow-tooltip="true">
        <template slot-scope="scope">{{ scope.row.project_num ? scope.row.project_num : "-" }}</template>
      </el-table-column>
      <el-table-column prop="true_name" label="签订人" :show-overflow-tooltip="true">
        <template slot-scope="scope">{{ scope.row.true_name ? scope.row.true_name : "-" }}</template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间" :show-overflow-tooltip="true">
        <template slot-scope="scope">{{ scope.row.create_time | fmtdate }}</template>
      </el-table-column>
      <el-table-column prop="status" label="状态" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span class="state wtj" v-if="scope.row.status == 0">未提交</span>
          <span class="state ytj" v-if="scope.row.status == 1">已提交</span>
          <span class="state ytg" v-if="scope.row.status == 2">已通过</span>
          <div v-if="scope.row.status == 3">
            <el-tooltip
              class="item"
              effect="dark"
              :content="scope.row.reject_reason"
              placement="top-start"
            >
              <span class="state btg">未通过</span>
            </el-tooltip>
          </div>
          <span class="state ylz" v-if="scope.row.status == 4">已流转</span>
          <span class="state yfp" v-if="scope.row.status == 5">已分配</span>
        </template>
      </el-table-column>
      <el-table-column prop="operation" label="操作" width="300px">
        <template slot-scope="scope">
          <el-button
            class="edit"
            size="mini"
            @click="handleSubmit(scope.row.id)"
            v-if="privilige_list.mission_contract.includes('render')"
          >提交</el-button>
          <el-button
            class="edit"
            size="mini"
            @click="editContract(scope.row.id)"
            v-if="privilige_list.mission_contract.includes('edit')"
          >编辑</el-button>
          <el-button
            class="edit"
            size="mini"
            @click="handleDetail(scope.row.id)"
            v-if="privilige_list.mission_contract.includes('view')"
          >详情</el-button>
          <el-button class="edit" size="mini" @click="handleFile(scope.row.id)">附件</el-button>
          <el-button
            class="del"
            size="mini"
            @click="handleDelete(scope.row.id)"
            v-if="privilige_list.mission_contract.includes('delete')"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block" v-if="contract.total > 0">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="contractParams.page"
        :page-sizes="[2, 10, 20, 40]"
        :page-size="contractParams.limit"
        layout="sizes, prev, pager, next,total,jumper"
        :total="contract.total"
      ></el-pagination>
    </div>

    <el-dialog title="提交" :visible.sync="dialogAudit" width="400px">
      <el-form
        :model="AuditForm"
        :rules="AuditFormRule"
        ref="auditFormRef"
        :label-position="labelPosition"
      >
        <el-form-item label="部门">
          <el-select
            v-model="AuditForm.role"
            placeholder="请选择部门"
            @change="getOrganization"
            style="width: 100%"
          >
            <el-option
              v-for="item in auditOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审核人">
          <el-select
            v-model="AuditForm.member"
            placeholder="请选择审核人"
            style="width: 100%"
            @change="getMemberId"
          >
            <el-option
              v-for="item in auditMember"
              :key="item.id"
              :label="item.true_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveAudit()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  inject: ["reload"],
  data () {
    return {
      options_human: [],//签订人下拉框
      options_class: [],//签订部门下拉框
      value_status: '',//状态筛选v-model
      loading: true,
      labelPosition: "top",
      contractTotal: 0,
      contractParams: {
        page: 1,
        limit: 10,
        keywords: "",
        order: "id desc",
        value_class: "",//签订部门v-model
        value_human: "",//签订人v-model
        sign_person: '',
        status: 3
      },
      AuditForm: {
        id: '',//合同id
      },
      AuditFormRule: {},
      auditOptions: [],//审核部门
      auditMan: '',//审核人
      auditMember: [],//审核人列表
      options: [],
      role: [],
      member: [],
      visible: false,
      dialogAudit: false,//提交dialog框
      privilige_list: [],
    };
  },
  activated () {
    // this.handleSearch();
    this.contractParams
    this.$store.dispatch("getContractList", this.contractParams);
    console.log(this.contractParams, 'params')
  },
  computed: {
    ...mapState(["contract"]),
  },
  created () {
    // this.$store.dispatch("getContractList", this.contractParams);
    this.getList()
    this.getitemId();
    let data = localStorage.getItem('privilige')
    data = JSON.parse(data)
    this.privilige_list = data
  },
  methods: {
    getList () {
      this.$store.dispatch("getContractList", this.contractParams);
    },
    // 提交合同选择部门change事件
    getOrganization (id) {
      this.axios.get('/api/organization/get_organization_reviewer', { params: { organization_id: id } }).then((res) => {
        this.auditMember = res.data
      })
    },
    humanSelect (data) {
      this.contractParams.sign_person = data
      this.$store.dispatch("getContractList", { ...this.contractParams, sign_person: data });
    },
    classSelect (id) {
      console.log(id, 'qiandingbumen')
      this.value_human = ''
      this.axios
        .get("/api/member/select_member", { params: { role_id: id } })
        .then((res) => {
          console.log(res, 9999999)
          this.options_human = res.data;
        })
        .catch((err) => {
          console.log("err:", err);
        });
    },
    // 获取签订部门
    getitemId () {
      this.axios
        .get("/api/organization/select_organization", { params: { has_manager: 1 } })
        .then((res) => {
          this.options_class = res.data;
        });
    },
    handleSearch () {
      this.$store.dispatch("getContractList", { ...this.contractParams, page: '', limit: '' });
      this.contractParams.page = 1
    },
    handleSizeChange (val) {
      this.contractParams.limit = val;
      this.$store.dispatch("getContractList", this.contractParams);
    },
    handleCurrentChange (val) {
      this.contractParams.page = val;
      this.$store.dispatch("getContractList", { ...this.contractParams });
    },
    editContract (id) {
      this.$router.push("/contract/add?id=" + id);
    },
    handleDetail (id) {
      this.$router.push("/contract/detail?id=" + id);
    },
    getDepartment () {
      this.axios.get("/api/organization/select_organization").then((res) => {
        this.role = res.data;
        this.auditOptions = res.data
      });
    },
    getMemberId (id) {
      this.member_id = id;
    },
    saveAudit () {
      console.log(this.AuditForm, '合同提交')
      this.axios.post("/api/contract/audit", {
        id: this.AuditForm.id,
        reviewer: this.AuditForm.member
      }).then(() => {
        this.$message({
          type: "success",
          message: "提交成功!",
        });
        this.$store.dispatch("getContractList", this.contractParams);
      });
    },
    handleSubmit (id) {
      this.getDepartment();
      this.AuditForm = {}
      this.dialogAudit = true
      this.AuditForm.id = id
    },
    handleFile (id) {
      console.log("合同列表附件id：", id)
      this.$router.push({ path: '/contract/annex', query: { id: id } })
    },
    handleDelete (id) {
      this.$confirm("确定删除此项目数据，删除后无法恢复！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          console.log(res);
          this.axios.delete("/api/contract/delete", {
            params: {
              id: id,
            },
          }).then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.reload();
            this.$store.dispatch("getContractList", this.contractParams);
          });
        })
        .catch((res) => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
  beforeUpdate () {
    this.loading = false;
  },
};
</script>

<style scoped="scoped">
.btg {
  color: #ff6f7a;
  font-weight: bold;
}

.ytg {
  color: #cacddf;
  font-weight: bold;
}

.yfp,
.ylz {
  color: #1ec300;
  font-weight: bold;
}

.ytj {
  color: #1ec300;
  font-weight: bold;
}

.el-progress__text {
  color: #0f38ff;
  font-weight: bold;
  font-size: 14px;
}
div/deep/.el-dialog__header {
  font-weight: bold;
}
</style>
