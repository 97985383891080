<template>
  <!-- 我的任务-附加项目 -->
  <div>
    <div class="topUsers" style="margin-top:24px">
      <div>
        <el-select
          placeholder="请选择项目类型"
          v-model="missionParams.project_type"
          @change="getproductType()"
        >
          <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <el-input
          v-model="missionParams.keywords"
          placeholder="请输入项目名称关键字"
          @keyup.enter.native="handleSearch()"
        ></el-input>
        <el-button class="search" @click="handleSearch()">查询</el-button>
      </div>
    </div>
    <el-table
      v-loading="loading"
      element-loading-text="Loading···"
      :data="mission.data"
      style="width: 100%"
    >
      <el-table-column prop="company_name" label="企业名称" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{
          scope.row.company_name ? scope.row.company_name : "-"
          }}
        </template>
      </el-table-column>
      <el-table-column prop="product_name" label="项目名称" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{
          scope.row.product_name ? scope.row.product_name : "-"
          }}
        </template>
      </el-table-column>
      <el-table-column prop="product_type" label="项目类型" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{
          scope.row.product_type ? scope.row.product_type : "-"
          }}
        </template>
      </el-table-column>
      <el-table-column prop="signatory_name" label="创建人" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{
          scope.row.signatory_name ? scope.row.signatory_name : "-"
          }}
        </template>
      </el-table-column>
      <el-table-column prop="assign_name" label="分配人" :show-overflow-tooltip="true">
        <template slot-scope="scope">{{ scope.row.assign_name ? scope.row.assign_name : "-" }}</template>
      </el-table-column>
      <el-table-column prop="create_time" label="分配时间" :show-overflow-tooltip="true">
        <template slot-scope="scope">{{ scope.row.create_time ? scope.row.create_time : "-" }}</template>
      </el-table-column>
      <el-table-column prop="status" label="状态" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span v-if="!scope.row.status">未开始</span>
          <span style="color:#F5C14F" v-if="scope.row.status == 1">开展中</span>
          <span style="color:#FF0F0F" v-if="scope.row.status == 3">未通过</span>
        </template>
      </el-table-column>
      <el-table-column prop="operation" label="操作" width="150px" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <el-button
            class="edit"
            size="mini"
            v-if="privilige_list.mission_order.includes('start') && scope.row.status == 0"
            @click="handleStart(scope.row.id)"
          >开始</el-button>
          <el-button
            class="edit"
            size="mini"
            v-if="scope.row.status == 1 || scope.row.status == 3"
            @click="handleContinue(scope.row.id)"
          >继续</el-button>
          <!-- <el-button
            v-if="privilige_list.mission_order.includes('reject')"
            class="edit"
            size="mini"
            @click="handleBack(scope.row.id)"
          >驳回</el-button>-->
        </template>
      </el-table-column>
    </el-table>
    <div class="block" v-if="mission.total > 0">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="missionParams.page"
        :page-sizes="[2, 10, 20, 40]"
        :page-size="missionParams.limit"
        layout="sizes, prev, pager, next,total,jumper"
        :total="mission.total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      mission: [],//列表
      options: [],
      loading: true,
      missionParams: {
        page: 1,
        limit: 10,
        company_name: "",
        project_type: "",
        order: "id desc",
        is_additional: 1,
      },
      privilige_list: [],
    }
  },
  created () {
    let arr = localStorage.getItem('privilige')
    this.privilige_list = JSON.parse(arr)
    // this.$store.dispatch("getMissionList", this.missionParams);
    this.getList()
  },
  methods: {
    getList () {
      this.axios.get('/api/mission/list', { params: { ...this.missionParams } }).then((res) => {
        this.mission = res.data
      })
    },
    // getMissionList () {
    //   this.axios.get('/api/mission/list', { params: { ...this.missionParams } }).then((res) => {
    //     this.mission = res.data
    //   })
    // },
    handleSizeChange (val) {
      this.missionParams.limit = val;
      this.$store.dispatch("getMissionList", this.missionParams);
    },
    handleCurrentChange (val) {
      this.missionParams.page = val;
      this.$store.dispatch("getMissionList", { ...this.missionParams, keywords: '' });
    },
    handleSearch () {
      this.$store.dispatch("getMissionList", { ...this.missionParams, page: '', limit: '' });
    },
    getproductType () {
      this.axios.get("/api/policy/select_product_type").then((res) => {
        this.options = res.data;
        this.options.unshift(this.opt);
        this.getList()
      });
    },
    handleContinue (id) {
      console.log(id);
      this.$store.state.mission.data.forEach((item) => {
        if (item.id == id) {
          this.order_id = item.order_id;
        }
      });
      this.$router.push("/project/process?id=" + this.order_id);
    },
    handleStart (id) {
      this.$router.push("/mission/start?id=" + id);
    },
    // handleBack () {
    //   console.log('我的任务-项目列表-驳回')
    // },
  },
  beforeUpdate () {
    this.loading = false;
  },

}
</script>

<style>
</style>