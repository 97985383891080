<template>
  <!-- 我的任务-合同审核-子组件 -->
  <div class="Authority">
    <div class="topUsers" style="margin-top:24px">
      <div>
        <el-input
          v-model="examineParams.keywords"
          placeholder="请输入企业名称关键字"
          @keyup.enter.native="handleSearch()"
        ></el-input>
        <el-button class="search" @click="handleSearch()">查询</el-button>
      </div>
    </div>
    <el-table
      v-loading="loading"
      element-loading-text="正在加载中···"
      :data="examine.data"
      style="width: 100%"
    >
      <el-table-column prop="company_name" label="企业名称" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="area" label="所属区域" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="contract_name" label="合同名称" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="ht_num" label="合同编号" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="type" label="合同类型" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span v-if="scope.row.type == 1">独立</span>
          <span v-if="scope.row.type == 2">非独立</span>
        </template>
      </el-table-column>
      <el-table-column prop="project_num" label="项目数量" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="true_name " label="签订人" :show-overflow-tooltip="true">
        <template slot-scope="scope">{{scope.row.true_name ? scope.row.true_name :'-'}}</template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间" :show-overflow-tooltip="true">
        <template slot-scope="scope">{{ scope.row.create_time | fmtdate }}</template>
      </el-table-column>s
      <el-table-column prop="operation" label="操作" width="280px">
        <template slot-scope="scope">
          <el-button
            v-if="privilige_list.contract_examine.includes('pass')"
            class="edit"
            size="mini"
            @click="handlepass(scope.row.id)"
          >通过</el-button>
          <el-button
            v-if="privilige_list.contract_examine.includes('reject')"
            class="edit"
            size="mini"
            @click="handleReject(scope.row.id)"
          >驳回</el-button>
          <el-button
            v-if="privilige_list.contract_examine.includes('view')"
            class="edit"
            size="mini"
            @click="handleDetail(scope.row.id)"
          >详情</el-button>
          <el-button
            v-if="privilige_list.contract_examine.includes('view_file')"
            class="edit"
            size="mini"
            @click="handleFile(scope.row.id)"
          >附件</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block" v-if="examine.total > 0">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="examineParams.page"
        :page-sizes="[2, 10, 20, 40]"
        :page-size="examineParams.limit"
        layout="sizes, prev, pager, next,total,jumper"
        :total="examine.total"
      ></el-pagination>
    </div>
    <el-dialog title="驳回" :visible.sync="dialogFormVisible" width="300px" height="400px">
      <el-form :model="form" :rules="formRule" ref="formRef" :label-position="labelPosition">
        <el-form-item label="驳回原因">
          <el-input type="textarea" v-model="form.reject_reason"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveReject()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  inject: ["reload"],
  data () {
    return {
      loading: true,
      labelPosition: "top",
      examineTotal: 0,
      examineParams: {
        page: 1,
        limit: 10,
        keywords: "",
        order: "id desc",
      },
      options: [],
      value: "",
      input: "",
      dialogFormVisible: false,
      form: {
        id: "",
        reject_reason: "",
      },
      formRule: {},
      formLabelWidth: "60px",
      privilige_list: [],
    };
  },
  activated () {
    // this.handleSearch();
    this.examineParams
    this.$store.dispatch("getExamineList", this.examineParams);
  },
  computed: {
    ...mapState(["examine"]),
  },
  created () {
    let arr = localStorage.getItem('privilige')
    this.privilige_list = JSON.parse(arr)
    this.$store.dispatch("getExamineList", this.examineParams);
  },
  methods: {
    getList () {
      this.$store.dispatch("getExamineList", this.examineParams);
    },
    handleSearch () {
      this.$store.dispatch("getExamineList", { ...this.examineParams, page: '', limit: '' });
      this.examineParams.page = 1
    },
    handleSizeChange (val) {
      this.examineParams.limit = val;
      this.$store.dispatch("getExamineList", this.examineParams);
    },
    handleCurrentChange (val) {
      this.examineParams.page = val;
      this.$store.dispatch("getExamineList", { ...this.examineParams });
    },
    handleFile (id) {
      this.$router.push({ path: '/examine/annex', query: { id: id } })
    },
    handleDetail (id) {
      this.$router.push("/contract/detail?id=" + id);
    },
    handleReject (id) {
      console.log(id);
      this.dialogFormVisible = true;
      this.form.id = id;
    },
    saveReject () {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.axios
            .post("/api/contract/reject", {
              id: this.form.id,
              reject_reason: this.form.reject_reason,
            })
            .then((res) => {
              console.log(res);
              this.$message.success("驳回成功");
              this.$store.dispatch("getExamineList", this.examineParams);
              this.reload();
            })
            .catch((res) => {
              console.log("err:", res);
            });
          this.dialogFormVisible = false;
        } else {
          console.log("输入错误");
        }
      });
    },
    handlepass (id) {
      this.$confirm("确定通过合同的审核，操作不可撤回！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios.post("/api/contract/pass", {
            id: id,
          }).then(() => {
            this.$message({
              type: "success",
              message: "通过审核!",
            });
            this.$store.dispatch("getExamineList", this.examineParams);
            this.reload();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消提交",
          });
        });
    },
  },
  beforeUpdate () {
    this.loading = false;
  },
};
</script>

<style scoped="scoped"></style>
