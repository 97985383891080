<template>
  <!-- 我的任务-开票收款-子组件 -->
  <div class="Authority">
    <div class="topUsers" style="margin-top:24px">
      <div>
        <el-input
          v-model="financeParams.company_name"
          placeholder="请输入企业名称关键字"
          @keyup.enter.native="handleSearch()"
        ></el-input>
        <el-button class="search" @click="handleSearch()">查询</el-button>
      </div>
    </div>
    <el-table
      v-loading="loading"
      element-loading-text="正在加载中···"
      :data="finance.data"
      style="width: 100%"
    >
      <el-table-column prop="company_name" label="企业名称" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{
          scope.row.company_name ? scope.row.company_name : "-"
          }}
        </template>
      </el-table-column>
      <el-table-column prop="contract_name" label="合同名称" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{
          scope.row.contract_name ? scope.row.contract_name : "-"
          }}
        </template>
      </el-table-column>
      <el-table-column prop="product_name" label="项目名称" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{
          scope.row.product_name ? scope.row.product_name : "-"
          }}
        </template>
      </el-table-column>
      <el-table-column prop="signatory_name" label="签单人" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{
          scope.row.signatory_name ? scope.row.signatory_name : "-"
          }}
        </template>
      </el-table-column>
      <el-table-column prop="down_time" label="签单时间" :show-overflow-tooltip="true">
        <template slot-scope="scope">{{ scope.row.down_time ? scope.row.down_time : "-" }}</template>
      </el-table-column>
      <el-table-column prop="receivables" label="应收款（元）" :show-overflow-tooltip="true">
        <template slot-scope="scope">{{ scope.row.receivables ? scope.row.receivables : "-" }}</template>
      </el-table-column>
      <el-table-column prop="received" label="已收款（元）" :show-overflow-tooltip="true">
        <template slot-scope="scope">{{ scope.row.received ? scope.row.received : "-" }}</template>
      </el-table-column>
      <el-table-column prop="check" label="核销" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span class="state whx" v-if="scope.row.check == 1" style="color: #ff6f7a">
            <i></i>未核销
          </span>
          <span class="state yhx" v-if="scope.row.check == 2" style="color: #cacddf">
            <i></i>已核销
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="refund" label="退款" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span class="state whx" v-if="scope.row.refund == 1">
            <i></i>-
          </span>
          <span class="state yhx" v-if="scope.row.refund == 2" style="color: #ff6f7a">
            <i></i>已拒绝
          </span>
          <span class="state whx" v-if="scope.row.refund == 3" style="color: #fdaf00">
            <i></i>退款中
          </span>
          <span class="state yhx" v-if="scope.row.refund == 4" style="color: #1ec300">
            <i></i>已同意
          </span>
          <span class="state whx" v-if="scope.row.refund == 5" style="color: #cacddf">
            <i></i>已退款
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="operation" label="操作" width="350px">
        <template slot-scope="scope">
          <el-button class="edit" size="mini" v-if="scope.row.receipt == 1">未申请</el-button>
          <el-button class="already" size="mini" v-if="scope.row.receipt == 3">已开票</el-button>
          <el-button
            class="edit"
            size="mini"
            @click="handleEdit(scope.row.id)"
            v-if="privilige_list.mission_invoice.includes('invoice')"
          >去开票</el-button>
          <el-button
            v-if="privilige_list.mission_invoice.includes('edit')"
            class="edit"
            size="mini"
            @click="editFinance(scope.row.id)"
          >编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block" v-if="finance.total > 0">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="financeParams.page"
        :page-sizes="[2, 10, 20, 40]"
        :page-size="financeParams.limit"
        layout="sizes, prev, pager, next,total,jumper"
        :total="finance.total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  inject: ["reload"],
  data () {
    return {
      loading: true,
      financeParams: {
        page: 1,
        limit: 10,
        company_name: "",
        receipt: 1,
        check: "",
        order: "id desc",
      },
      privilige_list: [],
    };
  },
  activated () {
    // this.handleSearch();
    this.financeParams
    this.$store.dispatch("getFinanceList", this.financeParams);

  },
  computed: {
    ...mapState(["finance"]),
  },
  created () {
    let arr = localStorage.getItem('privilige')
    this.privilige_list = JSON.parse(arr)
    this.$store.dispatch("getFinanceList", this.financeParams);
  },
  methods: {
    getList () {
      this.$store.dispatch("getFinanceList", this.financeParams);
    },
    handleSizeChange (val) {
      this.financeParams.limit = val;
      this.$store.dispatch("getFinanceList", this.financeParams);
    },
    handleCurrentChange (val) {
      this.financeParams.page = val;
      this.$store.dispatch("getFinanceList", { ...this.financeParams });
    },
    handleSearch () {
      this.$store.dispatch("getFinanceList", { ...this.financeParams, page: '', limit: '' });
      this.financeParams.page = 1
    },
    editFinance (id) {
      console.log(1);
      this.$router.push("/finance/edit?id=" + id);
    },
    handleEdit (id) {
      this.$router.push("/finance/info?id=" + id);
    },
  },
  beforeUpdate () {
    this.loading = false;
  },
};
</script>
<style>
.already {
  color: #cacddf;
  background: #f2f3f9 !important;
  border: none;
}
.state {
  font-weight: bold;
}
</style>
