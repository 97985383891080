<template>
  <!-- 我的任务-项目任务 -->
  <div>
    <div class="topUsers" style="margin-top:24px">
      <div>
        <el-select
          placeholder="请选择项目类型"
          v-model="missionParams.project_type"
          @change="getproductType()"
        >
          <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <el-input
          v-model="missionParams.keywords"
          placeholder="请输入项目名称关键字"
          @keyup.enter.native="handleSearch()"
        ></el-input>
        <el-button class="search" @click="handleSearch()">查询</el-button>
      </div>
    </div>
    <el-table
      v-loading="loading"
      element-loading-text="Loading···"
      :data="mission.data"
      style="width: 100%"
    >
      <el-table-column prop="company_name" label="企业名称" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{
          scope.row.company_name ? scope.row.company_name : "-"
          }}
        </template>
      </el-table-column>
      <el-table-column prop="product_name" label="项目名称" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{
          scope.row.product_name ? scope.row.product_name : "-"
          }}
        </template>
      </el-table-column>
      <el-table-column prop="product_type" label="项目类型" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{
          scope.row.product_type ? scope.row.product_type : "-"
          }}
        </template>
      </el-table-column>
      <el-table-column prop="signatory_name" label="创建人" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{
          scope.row.signatory_name ? scope.row.signatory_name : "-"
          }}
        </template>
      </el-table-column>
      <el-table-column prop="assign_name" label="分配人" :show-overflow-tooltip="true">
        <template slot-scope="scope">{{ scope.row.assign_name ? scope.row.assign_name : "-" }}</template>
      </el-table-column>
      <el-table-column prop="create_time" label="分配时间" :show-overflow-tooltip="true">
        <template slot-scope="scope">{{ scope.row.create_time ? scope.row.create_time : "-" }}</template>
      </el-table-column>
      <el-table-column prop="status" label="附加项目状态" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span v-if="!scope.row.additional_status">未附加</span>
          <span style="color:#F5C14F" v-if="scope.row.additional_status == 1">已开始</span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span style="color:#F5C14F" v-if="scope.row.status == 1">开展中</span>
          <span style="color:#5CC038" v-if="scope.row.status == 2">已结案</span>
          <span style="color:#FF0F0F" v-if="scope.row.status == 3">未通过</span>
          <span style="color:#FF0F0F" v-if="scope.row.status == 6">已终止</span>
        </template>
      </el-table-column>
      <el-table-column prop="operation" label="操作" width="250px" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <el-button
            class="edit"
            size="mini"
            v-if="privilige_list.mission_order.includes('start') && scope.row.status == 0"
            @click="handleStart(scope.row.id)"
          >开始</el-button>
          <el-button
            class="edit"
            size="mini"
            v-if="scope.row.status != 0"
            @click="handleContinue(scope.row.id)"
          >继续</el-button>
          <el-button
            v-if="privilige_list.mission_order.includes('reject') && scope.row.status == 2 || scope.row.status == 0"
            class="edit"
            size="mini"
            @click="handleBack(scope.row.id)"
          >退回</el-button>
          <el-button v-if="scope.row.status != 0" class="edit" size="mini">- - -</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block" v-if="mission.total > 0">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="missionParams.page"
        :page-sizes="[2, 10, 20, 40]"
        :page-size="missionParams.limit"
        layout="sizes, prev, pager, next,total,jumper"
        :total="mission.total"
      ></el-pagination>
    </div>
    <el-dialog title="退回" top="45vh" :visible.sync="dialogFormVisible" width="300px" height="400px">
      <div>确定是否退回项目？</div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveReject()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  inject: ["reload"],
  data () {
    return {
      back_id: '',
      labelPosition: 'top',
      loading: true,
      opt: { id: "0", name: "全部" },
      missionParams: {
        page: 1,
        limit: 10,
        company_name: "",
        project_type: "",
        order: "id desc",
        is_additional: 0
      },
      dialogFormVisible: false,
      order_id: "",
      options: [],
      privilige_list: [],//权限显隐列表
      formRule: {},
      form: {
        id: "",
        reject_reason: "",//驳回原因
      },
    }
  },
  mounted () {
    this.getproductType();
    this.missionParams
    this.$store.dispatch("getMissionList", this.missionParams);
  },
  computed: {
    ...mapState(["mission"]),
  },
  created () {
    let arr = localStorage.getItem('privilige')
    this.privilige_list = JSON.parse(arr)
    this.$store.dispatch("getMissionList", this.missionParams);
  },
  methods: {
    getList () {
      this.$store.dispatch("getMissionList", this.missionParams);
    },
    handleSizeChange (val) {
      this.missionParams.limit = val;
      this.$store.dispatch("getMissionList", this.missionParams);
    },
    handleCurrentChange (val) {
      this.missionParams.page = val;
      this.$store.dispatch("getMissionList", { ...this.missionParams, keywords: '' });
    },
    handleSearch () {
      this.$store.dispatch("getMissionList", { ...this.missionParams, page: '', limit: '' });
    },
    getproductType () {
      this.axios.get("/api/policy/select_product_type").then((res) => {
        this.options = res.data;
        this.options.unshift(this.opt);
        this.$store.dispatch("getMissionList", this.missionParams);
      });
    },
    handleContinue (id) {
      console.log(id);
      this.$store.state.mission.data.forEach((item) => {
        if (item.id == id) {
          this.order_id = item.order_id;
        }
      });
      this.$router.push("/project/process?id=" + this.order_id);
    },
    handleStart (id) {
      this.$router.push("/mission/start?id=" + id);
    },
    handleBack (id) {
      this.dialogFormVisible = true
      this.back_id = id
    },
    saveReject () {
      this.axios.post('/api/mission/reject', { id: this.back_id }).then((res) => {
        this.$message.success(res.message)
        this.dialogFormVisible = false
        this.$store.dispatch("getMissionList", this.missionParams);
      })
    },
  },

  beforeUpdate () {
    this.loading = false;
  },
}
</script>

<style scoped>
.whx {
  color: #ff6f7a;
  font-weight: bold;
}

.yhx {
  color: #cacddf;
  font-weight: bold;
}

.ytk {
  color: #ffbe2c;
  font-weight: bold;
}
div/deep/.el-dialog__title {
  font-weight: bold;
}
</style>