<template>
  <!-- 我的任务-流转分配-子组件 -->
  <div>
    <div class="topUsers" style="margin-top:24px">
      <div>
        <el-input
          clearable
          v-model="tableParams.keywords"
          placeholder="请输入合同名称关键字"
          @keyup.enter.native="handleSearch()"
        ></el-input>
        <el-button class="search" @click="handleSearch()">查询</el-button>
      </div>
    </div>
    <el-table
      v-loading="loading"
      element-loading-text="Loading···"
      :data="tableData"
      style="width: 100%"
    >
      <el-table-column prop="company_name" label="企业名称">
        <template slot-scope="scope">{{scope.row.company_name?scope.row.company_name:'-'}}</template>
      </el-table-column>
      <el-table-column prop="contract_name" label="合同名称">
        <template slot-scope="scope">{{scope.row.contract_name?scope.row.contract_name:'-'}}</template>
      </el-table-column>
      <el-table-column prop="ht_num" label="合同编号"></el-table-column>
      <el-table-column prop="true_name" label="创建人">
        <template slot-scope="scope">{{scope.row.true_name?scope.row.true_name:'-'}}</template>
      </el-table-column>
      <el-table-column prop="assign_name" label="流转人">
        <template slot-scope="scope">{{scope.row.assign_name?scope.row.assign_name:'-'}}</template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间">
        <template slot-scope="scope">{{(scope.row.create_time?scope.row.create_time:'-') | fmtdate}}</template>
      </el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <span v-if="!scope.row.status">-</span>
          <span style="color:#FFBE2C" v-if="scope.row.status == 1">已提交</span>
          <span style="color:#5CC038" v-if="scope.row.status == 2">已通过</span>
          <span style="color:#FF0F0F" v-if="scope.row.status == 3">未通过</span>
          <span style="color:#5CC038" v-if="scope.row.status == 4">已流转</span>
          <span style="color:#5CC038" v-if="scope.row.status == 5">已分配</span>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="操作" width="250px">
        <template slot-scope="scope">
          <el-button
            v-if="privilige_list.mission_flow.includes('flow')"
            class="edit"
            size="mini"
            @click="handleflow(scope.row.id)"
          >流转</el-button>
          <el-button
            v-if="privilige_list.mission_flow.includes('file')"
            class="edit"
            size="mini"
            @click="handleAnnex(scope.row.id)"
          >附件</el-button>
          <el-button
            v-if="privilige_list.mission_flow.includes('view')"
            class="edit"
            size="mini"
            @click="handleDetail(scope.row.id)"
          >详情</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="tableParams.page"
        :page-sizes="[2, 10, 20, 40]"
        :page-size="tableParams.limit"
        layout="sizes, prev, pager, next,total,jumper"
        :total="total"
      ></el-pagination>
    </div>

    <el-dialog title="流转" :visible.sync="dialogflowFormVisible" width="400px">
      <el-form
        :model="flowform"
        :rules="flowformRule"
        ref="flowformRef"
        :label-position="labelPosition"
      >
        <el-form-item label="流转类型">
          <el-select
            v-model="flowform.type"
            placeholder="请选择流转类型"
            @change="handleChangeType"
            style="width: 100%"
          >
            <el-option label="流转" :value="1"></el-option>
            <el-option label="分配" :value="2"></el-option>
            <el-option label="流转撤回" :value="3"></el-option>
            <el-option label="分配撤回" :value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="部门" v-if="roleStatus">
          <el-select
            v-model="flowform.role"
            placeholder="请选择部门"
            @change="getStaff"
            style="width: 100%"
          >
            <el-option v-for="item in role" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="typeHuman" v-if="typeStatus">
          <el-select
            v-model="flowform.member"
            :placeholder="'请选择' + typeHuman"
            style="width: 100%"
            @change="getMemberId"
          >
            <el-option
              v-for="item in member"
              :key="item.id"
              :label="item.true_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="xmText">
          <el-select
            v-model="flowform.contract_project"
            multiple
            placeholder="请选择项目/可多选"
            style="width: 100%"
          >
            <el-option
              v-for="item in this.contract_project.contract_project"
              :key="item.contract_project_id"
              :label="item.title"
              :value="item.contract_project_id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveFlow()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableParams: {
        token: '',
        page: 1,
        limit: 10,
        keywords: ''
      },
      total: 0,
      tableData: [],//列表数据
      contract_project: [],
      // form: {
      //   role: "",
      //   member: "",
      //   contract_project: [],
      //   project_id: [],
      //   contract_id: "",
      //   member_id: "",
      // },
      role: [],
      member: [],
      labelPosition: "top",
      dialogflowFormVisible: false,
      formRule: {},
      flowformRule: {},
      flowform: {
        role: "",
        member: "",
        contract_project: [],
        project_id: [],
        contract_id: "",
        member_id: "",
        type: '',//流转类型（流转/分配）
      },
      typeHuman: '',//流转人/分配人 默认状态
      typeStatus: false,//流转人/分配人 from表单默认隐藏
      roleStatus: true,//部门 from表单默认隐藏，流转/分配撤回需隐藏
      privilige_list: [],
      xmText: '项目',
      id: '',
      loading: false,
    }
  },
  created () {
    this.tableParams.token = localStorage.getItem('token')
    this.getList()
    let arr = localStorage.getItem('privilige')
    this.privilige_list = JSON.parse(arr)
  },
  methods: {
    // 详情
    handleDetail (id) {
      this.$router.push({ path: '/contract/detail', query: { id: id } })
    },
    // 附件
    handleAnnex (id) {
      this.$router.push({ path: '/contract/annex', query: { id: id } })
    },
    handleSizeChange (val) {
      this.tableParams.limit = val;
      this.axios.get('/api/mission/list', { params: { ...this.tableParams } }).then((res) => {
        this.tableData = res.data.data
        this.total = res.data.total
      })
    },
    handleCurrentChange (val) {
      this.tableParams.page = val;
      this.axios.get('/api/mission/flow_contract_list', { params: { ...this.tableParams } }).then((res) => {
        this.tableData = res.data.data
        this.total = res.data.total
      })
    },
    getDepartment () {
      this.axios.get("/api/organization/select_organization").then((res) => {
        this.role = res.data;
        this.auditOptions = res.data
      });
    },
    getList () {
      this.axios.get('/api/mission/flow_contract_list', { params: { ...this.tableParams } }).then((res) => {
        this.tableData = res.data.data
        this.total = res.data.total
        this.loading = false
      })
    },
    handleSearch () {
      this.axios.get('/api/mission/flow_contract_list', { params: { ...this.tableParams, page: '' } }).then((res) => {
        this.tableData = res.data.data
        this.total = res.data.total
      })
    },

    handleflow (id) {
      this.getDepartment()
      this.id = id
      this.flowform = {}
      this.contract_project = [];
      this.dialogflowFormVisible = true;
      this.typeStatus = false
      this.axios
        .get("/api/contract/flow", {
          params: {
            contract_id: id,
          },
        })
        .then((res) => {
          this.contract_project = res.data;
          this.contract_id = res.data.contract_id;
        });
    },

    handleChangeType () {
      this.$set(this.flowform, 'role', '')
      this.$set(this.flowform, 'member', '')
      this.contract_project = []
      this.$set(this.flowform, 'contract_project', '')
      if (this.flowform.type) this.typeStatus = true
      if (this.flowform.type == 1) {
        this.typeHuman = '流转人'
        this.roleStatus = true;
        this.xmText = '项目'
        this.axios
          .get("/api/contract/flow", {
            params: {
              contract_id: this.id,
            },
          })
          .then((res) => {
            console.log(res);
            this.contract_project = res.data;
            this.contract_id = res.data.contract_id;
          });
      }
      if (this.flowform.type == 2) {
        this.typeHuman = '分配人'
        this.roleStatus = true;
        this.xmText = '项目'
        this.axios
          .get("/api/contract/assign", {
            params: {
              contract_id: this.id,
            },
          })
          .then((res) => {
            console.log(res);
            this.contract_project = res.data;
            this.contract_id = res.data.contract_id;
          });
      }
      if (this.flowform.type == 3) {
        console.log('流转撤回')
        this.typeStatus = false;
        this.roleStatus = false;
        this.xmText = '流转撤回项目';
        this.axios
          .get("/api/contract/flow", {
            params: {
              contract_id: this.id,
            },
          })
          .then((res) => {
            console.log(res);
            this.contract_project = res.data;
            this.contract_id = res.data.contract_id;
          });
      }
      if (this.flowform.type == 4) {
        console.log('分配撤回')
        this.typeStatus = false;
        this.roleStatus = false;
        this.xmText = '分配撤回项目'
      }
    },

    getStaff (id) {
      console.log(id);
      console.log(this.flowform.type, 'type')
      if (this.flowform.type == 1) {
        this.axios
          .get("/api/organization/get_organization_flow", {
            params: {
              organization_id: id,
            },
          })
          .then((res) => {
            console.log(res);
            this.member = res.data;
          });
      } else if (this.flowform.type == 2) {
        this.axios
          .get("/api/organization/get_organization_assign", {
            params: {
              organization_id: id,
            },
          })
          .then((res) => {
            console.log(res);
            this.member = res.data;
          });
      }
    },

    getMemberId (id) {
      this.member_id = id;
    },

    saveFlow () {
      this.$refs.flowformRef.validate((valid) => {
        if (valid) {
          if (this.flowform.type == 1) {
            this.axios
              .post("/api/contract/flow_contract", {
                contract_id: this.contract_id,
                project_id: this.flowform.contract_project.join(","),
                member_id: this.member_id,
              })
              .then((res) => {
                this.dialogflowFormVisible = false;
                console.log(res);
                this.$message.success("流转成功");
                this.getList()
                this.dialogflowFormVisible = false;
              })
              .catch((res) => {
                console.log("err:", res);
              });
            this.dialogflowFormVisible = false;
          }
          if (this.flowform.type == 2) {
            this.axios
              .post("/api/contract/assign_contract", {
                contract_id: this.contract_id,
                project_id: this.flowform.contract_project.join(","),
                member_id: this.member_id,
              })
              .then((res) => {
                this.$message.success("分配成功");
                this.getList()
                this.dialogflowFormVisible = false;
              })
              .catch((res) => {
                console.log("err:", res);
              });
            this.dialogflowFormVisible = false;
          }
          if (this.flowform.type == 3) {
            this.axios
              .post("/api/contract/recall_contract_project", {
                contract_id: this.contract_id,
                project_id: this.flowform.contract_project.join(","),
                type: this.flowform.type
              })
              .then((res) => {
                this.$message.success(res.message);
                this.getList()
                this.dialogflowFormVisible = false;
              })
              .catch((res) => {
                console.log("err:", res);
              });
            this.dialogflowFormVisible = false;
          }
          if (this.flowform.type == 4) {
            this.axios
              .post("/api/contract/recall_contract_project", {
                contract_id: this.contract_id,
                project_id: this.flowform.contract_project.join(","),
                type: this.flowform.type
              })
              .then((res) => {
                this.$message.success(res.message);
                this.getList()
                this.dialogflowFormVisible = false;
              })
              .catch((res) => {
                console.log("err:", res);
              });
            this.dialogflowFormVisible = false;
          }
        } else {
          console.log("输入错误");
        }
      });
    },
  },
  beforeUpdate () {
    this.loading = false
  }
}
</script>

<style>
</style>